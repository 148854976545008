<template>
  <img :src="nativeImgPath" :alt="nativeData.name" class="native_img_style" />
</template>

<script>
import axios from "@/config/httpConfig";
export default {
  props: {
    nativeData: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否是 曹文一的 lightning公式图片的图片
     * 如果是lightning的公式图片预览就需要单独调用曹文一的接口进行预览
     * 预览地址和旧版的预览地址不一样
     */
    isLightningOriginal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 数据改变，则重新获取当前图片预览的路径地址用于预览
    nativeData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.useNativePreview();
        }
      },
    },
  },
  data() {
    return {
      nativeImgPath: "", //原生图片预览地址
    };
  },
  methods: {
    // 原生图片预览，不用插件的
    useNativePreview() {
      // 加载中状态
      this.wordShowLoading = true;
      const { id } = this.nativeData;

      if (this.isLightningOriginal) {
        /**
         * 这个地址先写死，地址配置和后台设置请求的地址不对应
         */
        axios
          .post(
            `${window.gw["DEV_CONSOLE"]}/devconsole/staticResource/detailStaticResource`,
            { id }
          )
          .then((res) => {
            const { data } = res;
            this.nativeImgPath = data?.cdnfileSource;
          });
      } else {
        this.nativeImgPath = `${
          this.$baseConfig.baseURL
        }/file/viewImg?id=${id}&accessToken=${this.$CCDK.CCToken.getToken()}`;
      }
      // 图片加载完成，告诉上级组件
      this.$nextTick(() => {
        document
          .querySelector(".native_img_style")
          .addEventListener("load", () => {
            // 关闭加载中状态
            this.wordShowLoading = false;
            this.$emit("loadSuccess");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.native_img_style {
  max-width: 100%;
  max-height: 100%;
}
</style>